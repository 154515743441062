import { mmcAjax } from '@/common/js/mmcModule';

function perCheck() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/apply/check`,
        crossDomain: true,
    })
}
// export function paySubmit(data) {

//     return mmcAjax({
//         method: 'post',
//         url: `${window.APIBasePath}mmc/pay/create/order`,
//         data,
//         crossDomain: true,
//     })
// }

export default {
    perCheck
}