<template>
    <div v-if="showPage" class="app">
        <section class="box1">
            <i></i>
            <p>报名成功</p>
        </section>
        <div class="btn-wrap">
            <div class="pr-btn">{{ btnStr }}</div>
        </div>
        <div class="guize">
            <p class="title">活动规则</p>
            <p class="desc">
                1、本次活动无报名人数限制，填写报名登记表后即为报名成功。好友助力活动开始时间为2023年10月8日12:00。<br>
                2、报名参加者需邀请好友通过宠物筹每人助力1分钱，满50人助力即可获得领取宠物粮资格，由于宠物粮数量有限，先到先得。<br>
                3、本次活动共有卫仕全价冻干猫粮20袋、卫仕全价冻干狗粮9袋，多种口味随机发货。<br>
                4、当有20人完成猫粮助力活动，猫粮助力活动自动结束；当有9人完成狗粮助力活动，狗粮助力活动自动结束。活动结束后不可再助力。猫粮助力活动与狗粮助力活动互不影响。<br>
                5、本次活动所有助力金额将捐给北京微爱基金会用于流浪动物救助。
            </p>
        </div>
    </div>
</template>

<script>
import api from './api';
import { authChecker, shareCtrl, tracking } from '@/common/js/mmcModule';
import utils from '@/common/js/utils';
import saUtils from 'sensors-data-utils';
var reqObj = utils.getRequestParams();
let pageKey = 'h5_jsb_onepoint_success';

export default {
    name: 'app',

    data() {
        return {
            btnStr: '活动暂未开始',
            showPage: false,
        };
    },
    methods: {
        confirm() {
            // location.href =
            //     "/v8/project/projwallet/index/index_v8.html?projuuid=" +
            //     reqObj.uuid;
        },
    },
    mounted() {
        authChecker.check({ notneedphone: 1 }, () => {
            showLoading('加载中...');
            api.perCheck().then(res => {
                hideLoading();
                if (res.code == 0) {
                    if (res.data.apply) {
                        this.showPage = true;
                        if (reqObj.ismock) {
                            res.data.activity_state = 1;
                        }
                        if (res.data.activity_state == 1) {
                            this.btnStr = '活动暂未开始';
                        }
                        if (
                            res.data.activity_state == 2 ||
                            res.data.activity_state == 3
                        ) {
                            location.href =
                                '/fund/activity/onepoint/detail?id=' +
                                res.data.penny_pet_id;
                        }
                    }
                }
            });

            shareCtrl.init({
                title: '抢129元卫仕猫粮/狗粮',
                imgUrl: `https://m.jisuchou.net/fund/onepoint-share.jpg`,
                desc: '邀请50位好友，每人助力1分钱',
                url: `https://m.jisuchou.net/fund/activity/onepoint/apply`,
                titleMoment: '抢129元卫仕猫粮/狗粮',
                shareRecord: {
                    scene: pageKey,
                },
            });
        });

        tracking.init();

        saUtils.init(pageKey);

        saUtils.pv();
    },

    components: {},
};
</script>

<style lang="less" scoped>
.app {
    padding: 15px;
    height: 100%;
    overflow-y: scroll;
    overflow: hidden;
    position: relative;
    width: 100vw;

    .box1 {
        text-align: center;
        // height: 105px;
        // vertical-align: bottom;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-top: 45px;

        i {
            width: 51px;
            height: 51px;
            background-image: url('./check-circle.png');
            background-size: contain;
        }
        p {
            font-size: 18px;
            color: #333;
            margin: 10px 0 0 0;
            font-weight: 500;
            height: 21px;
            line-height: 21px;
            font-weight: normal;
        }
    }
    .btn-submit {
        margin-bottom: 20px;
        position: fixed;
        bottom: 0;
        // left: 0px;
        // right: 20px;
        width: calc(100%-32px);
        // margin-right: 50px ;
    }

    .btn-wrap {
        // display: flex;
        // // text-align: center;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;

        margin-top: 40px;

        .pr-btn {
            padding: 15px 30px;
            // width: 255px;
            // background-image: linear-gradient(to right, #fe6045, #e91919);
            background-color: #fe771b;
            border-radius: 8px;
            line-height: 1em;
            font-size: 18px;
            color: #fff;
            min-width: 130px;
            font-weight: 600;
            margin: 0 30px;
        }
    }

    .guize {
        margin: 45px 30px;
        text-align: left;
        padding: 17px 10px;
        border: 1px solid #feaf49;
        border-radius: 8px;
        .title {
            color: #666666;
            font-size: 14;
            font-weight: 500;
            text-align: center;
        }
        .desc {
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            line-height: 1.4em;
            margin-top: 10px;
        }
    }
}
</style>
